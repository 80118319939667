import { api } from './api.config';
import { store } from '../store';

export const getServices = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return api.get('/api/v1/agent/services', {}, config);
};

export const validateMeterNumber = async (data) => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get(
        `/api/v1/agent/validate/meter?meter_number=${data.meterNumber}&disco_name=${data.disco_name}&meter_type=${data.meterType}`,
        {},
        config
    );
};

export const validateCableNumber = async ({ smart_card_number, cable_name }) => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get(`/api/v1/agent/validate/cable?smart_card_number=${smart_card_number}&cable_name=${cable_name}`, {}, config);
};

export const getApiServices = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return api.get('/api/v1/agent/api/services', {}, config);
};

export const getApiKeys = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return api.get('/api/v1/agent/api/keys', {}, config);
};

export const resetApiKeys = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return api.post('/api/v1/agent/api/keys', {}, config);
};
