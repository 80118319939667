import * as actionTypes from './constant';

export const initialState = {
    availableBalance: '0.00'
};

// ==============================|| AUTHENTICATION REDUCER ||============================== //

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_WALLET:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};

export default walletReducer;
