import * as actionTypes from './constant';

const initialState = {
    services: [],
    apiServices: [],
    apiKeys: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICES:
            return {
                ...state,
                services: action.payload
            };

        case actionTypes.SET_API_SERVICES:
            return {
                ...state,
                apiServices: action.payload
            };
        case actionTypes.SET_API_KEYS:
            return {
                ...state,
                apiKeys: action.payload
            };

        default:
            return state;
    }
}
