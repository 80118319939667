import React from 'react';
import {
    Grid,
    Link,
    Button,
    Input,
    Box,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    Stack,
    Typography,
    Divider,
    TextField,
    Avatar
} from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { IconPhone, IconSearch, IconX } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import TransactionsCard from './component/TransacitonCard';
import { getTransactionsHistory } from 'api/api.transaction';
import ModalC from 'views/dashboard/components/Modal';
import { logOutUser } from 'store/authAction';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { changeUserPassword } from 'api/api.auth';
import { toast } from 'react-toastify';
import * as yup from 'yup';

// ==============================|| TYPOGRAPHY ||============================== //

export const TextValue = ({ text, value }) => (
    <Stack>
        <Typography>{text}</Typography>
        <Typography fontWeight={'bold'}>{value}</Typography>
    </Stack>
);

export const initialChangePasswordValue = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};

export const changeValidationSchema = yup.object().shape({
    oldPassword: yup.string().required('Your old is Required'),
    newPassword: yup.string().required('New password is required'),
    confirmNewPassword: yup.string().required('Confirm new password is required')
});

const Profile = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { user } = useSelector((state) => state.auth);
    const toggleModal = () => setShowModal(!showModal);

    const handleChangePassword = () => {
        toggleModal();
    };

    const handleLogOut = () => {
        dispatch(logOutUser());
    };

    const changePassword = async (data) => {
        if (data.confirmNewPassword !== data.newPassword) {
            toast.info('Please check the input new password and confirm password are not the same');
            return;
        }
        setLoading(true);

        const response = await changeUserPassword({
            oldPassword: data.oldPassword,
            newPassword: data.newPassword
        });

        if (response.ok) {
            toast.success(response.data.message);
            toggleModal();
            setLoading(false);
            return;
        }

        toast.error(response.data ? response.data.message : response.problem);
        setLoading(false);
    };

    return (
        <>
            <ModalC isOpen={showModal} setOpen={toggleModal}>
                <Stack sx={{ cursor: 'pointer' }} direction={'row'} alignItems="center" justifyContent="space-between">
                    <Typography>Change Password</Typography>
                    <IconX onClick={toggleModal} />
                </Stack>
                <Typography marginTop="20px">Please provide the details below</Typography>
                <Formik
                    validationSchema={changeValidationSchema}
                    initialValues={initialChangePasswordValue}
                    onSubmit={(e) => changePassword(e)}
                >
                    {({ errors, values, handleBlur, handleChange, handleSubmit }) => (
                        <>
                            <Stack space="10px" mt="20px">
                                <TextField
                                    id="standard-basic"
                                    label="Old Password"
                                    name="oldPassword"
                                    variant="standard"
                                    value={values.oldPassword}
                                    onChange={handleChange('oldPassword')}
                                    onBlur={handleBlur('oldPassword')}
                                />
                                {errors.oldPassword && <Typography color="red">{errors.oldPassword}</Typography>}
                                <Input
                                    id="standard-basic"
                                    label="New Password"
                                    variant="standard"
                                    name="newPassword"
                                    value={values.newPassword}
                                    onChange={handleChange('newPassword')}
                                    onBlur={handleBlur('newPassword')}
                                />

                                {errors.newPassword && <Typography color="red">{errors.newPassword}</Typography>}
                                <TextField
                                    id="standard-basic"
                                    label="Confirm New Password"
                                    variant="standard"
                                    name="confirmNewPassword"
                                    value={values.confirmNewPassword}
                                    onChange={handleChange('confirmNewPassword')}
                                    onBlur={handleBlur('confirmNewPassword')}
                                />
                                {errors.confirmNewPassword && <Typography color="red">{errors.confirmNewPassword}</Typography>}
                            </Stack>
                            <Stack marginTop="30px">
                                <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
                                    Change Password
                                </LoadingButton>
                            </Stack>
                        </>
                    )}
                </Formik>
            </ModalC>
            <MainCard title="Profile" contentSX={{ px: 0 }}>
                <Box sx={{ px: { xs: '0px', sm: '20px' }, pb: '50px' }}>
                    <SubCard>
                        <Stack spacing={'10px'} alignItems={'center'} width="100%">
                            <Avatar
                                src={'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png'}
                                sx={{ height: '100px', width: '100px' }}
                            >
                                YB
                            </Avatar>
                            <Typography fontSize={'24px'} fontWeight="bold">
                                {user && `${user.firstName} ${user.lastName}`}
                            </Typography>
                            <Stack alignItems="center">
                                <Typography>Username</Typography>
                                <Typography fontWeight="bold" fontSize="20px">
                                    {user && user.username}
                                </Typography>
                            </Stack>
                            <SubCard>
                                <Stack width="100%" spacing="20px">
                                    <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                                        <Typography fontWeight={'bold'}>Name</Typography>
                                        <Typography fontWeight={'bold'}>{user && `${user.firstName} ${user.lastName}`}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                                        <Typography fontWeight={'bold'}>Phone Number</Typography>
                                        <Typography fontWeight={'bold'}>{user && `${user.phone}`}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} spacing={{ lg: '120px', sm: '120px', xs: '60px' }} alignItems={'center'}>
                                        <Typography fontWeight={'bold'}>Email Address</Typography>
                                        <Typography fontWeight={'bold'}>{user && `${user.email}`}</Typography>
                                    </Stack>
                                </Stack>
                            </SubCard>
                            <Stack width="100%" sx={{ px: { lg: '30px', sm: '30px', xs: '1px' } }} spacing={'10px'}>
                                <Button fullWidth variant="outlined" onClick={handleChangePassword}>
                                    Change Password
                                </Button>
                                <Button fullWidth variant="contained" onClick={handleLogOut}>
                                    Log Out
                                </Button>
                            </Stack>
                        </Stack>
                    </SubCard>
                </Box>
            </MainCard>
        </>
    );
};

export default Profile;
